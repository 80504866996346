import React, { useEffect, useState, useCallback, useContext } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import * as styles from "./detail.module.css"
// import Slider from "components/tools/slider.js"
import Slider from "components/tools/slide/Slide.js"
import SlidePagination from "components/tools/slide/slidePagination.js"
import queryString from "query-string"
import api from "apis"
import { navigate } from "gatsby-link"
import DetailHeader from "components/post/detailHeader"
import { storyCategory, table, speech } from "utils/data.js"
import {
  getDateYMD,
  textEdit,
  titleEdit,
  getThumb,
  getEpisode,
  getRawThumb,
  getNewTag,
} from "utils"
import Layout from "components/common/layout.js"
import { onLike } from "utils"
import InputTitle from "components/tools/InputTitle.js"
import { smalloffFavor, smallonFavor } from "images"
import { getCardListAsync } from "store/modules/postReducer.js"
import modalContext from "context/modalContext"
import { customCount } from "utils"
import peachIcons from "images/peachIcons.png"
import Story from "components/story/story"
import NewTag from "components/tools/newTag"
import Like from "components/tools/Like"
import dompurify from "dompurify"
const Detail = props => {
  const dispatch = useDispatch()
  const getCardList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getCardListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )
  const [newTag, setNewTag] = useState(false)
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const onModal = ({ target: { src } }) => {
    setmodalOpen(true)
    setModalMain(
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          setmodalOpen(false)
        }}
      >
        <img
          src={src}
          style={{ width: "670px", height: "auto", borderRadius: "10px" }}
          className={styles.preCard}
        />
      </div>
    )
  }

  const { cardList, cardTotal } = useSelector(
    ({ postReducer: { card } }) => card,
    shallowEqual
  )

  console.log(cardList)

  //
  const { illust_no, currentPage, pagePostSize } = queryString.parse(
    props.location.search
  )
  const [slidePage, setSlidePage] = useState(Number(currentPage))
  const [post, setPost] = useState({})
  const [associatedStory, setAssociatedStory] = useState([])

  useEffect(() => {
    ;(async () => {
      const post = await api.getCardStoryDetail(illust_no)
      console.log(post)
      const associatedStory = await api.associatedStory(illust_no, table.get(5))
      console.log(associatedStory)

      setAssociatedStory(associatedStory)
      setPost({
        card: {
          img: getRawThumb(post.IllustRaw.data_uuid),
          speech: speech[post.speech],
        },
        title: titleEdit(post.title),
        subTitle: post.subtitle,
        contents: post.bodyText,
        intro: textEdit(post.introText),
        time: getDateYMD(post.registerTime, "YYYY.MM.DD"),
        Like: post?.Like,
      })
    })()
  }, [illust_no, currentPage, pagePostSize])
  console.log(post?.contents)

  return (
    <Layout>
      <DetailHeader
        left={
          <div>
            <img
              src={post?.card?.img}
              style={{
                maxWidth: "250px",
                minWidth: "250px",
                height: "auto",
                maxHeight: "350px",
                border: "1px solid #dfdfdf",
                cursor: "pointer",
              }}
              onClick={onModal}
            />
          </div>
        }
        right={
          <div className={styles.rightBox}>
            <div className="tagBox">
              <div style={{ backgroundColor: "#E58A83" }}>
                {post?.card?.speech}
              </div>
              <NewTag registerTime={post?.time} />
            </div>
            <div className={styles.title}>{post?.title}</div>
            <div className={styles.intro}>{post?.intro}</div>
            <div>
              <div>
                <div>
                  <img
                    src={peachIcons}
                    style={{
                      minWidth: "45px",
                      height: "45px",
                      marginTop: "7px",
                    }}
                  />
                </div>
                <div>피치서가</div>
                <div
                  style={{
                    marginTop: "4px",
                  }}
                >
                  {post?.time}
                </div>
              </div>
              <div>
                {/* <img
                  src={post?.Like ? onFavor : offFavor}
                  onClick={e => {
                    onLike(e, illust_no, table.get(5))
                  }}
                  style={{
                    minWidth: "44px",
                    height: "44px",
                    marginTop: "7px",
                  }}
                /> */}
                <Like
                  on={smallonFavor}
                  off={smalloffFavor}
                  keyword={"illust"}
                  isLike={post?.Like}
                  style={{
                    img: {
                      minWidth: "44px",
                      height: "44px",
                      marginTop: "7px",
                    },
                  }}
                  postNumber={illust_no}
                />
              </div>
            </div>
          </div>
        }
      >
        {typeof window !== "undefined" ? (
          <div
            style={{
              minHeight: "300px",
              textAlign: "left",
              width: "800px",
              margin: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(post?.contents),
            }}
          />
        ) : null}
      </DetailHeader>

      <div className={styles.slideCon}>
        <InputTitle
          name={"연관 스토리"}
          titleSize={22}
          style={{
            margin: "50px 10px 29px 0px",
          }}
        />
        {/* 수정필요 */}
        <Slider name={"associated"}>
          {associatedStory?.map(post => {
            console.log(post)

            return (
              <Story
                key={post.story_no}
                registerTime={post.publishTime}
                story_no={post.story_no}
                title={post.title}
                introText={post.introText}
                thumb_title_uuid={post.thumb_title_uuid}
                cateMain={post.cateMain}
                cateSub={post.cateSub}
                Like={post.Like}
                free={post.free}
              />
            )
          })}
        </Slider>
      </div>
      <div className={styles.pageSlideCon}>
        <SlidePagination
          total={cardTotal}
          currentPage={slidePage}
          setCurrentPage={setSlidePage}
          postSize={pagePostSize}
          getlist={getCardList}
          viewAll={() => {
            navigate("/visible/card/list")
          }}
          title={"더 많은 그림단어"}
          line={true}
        >
          <div className={styles.pageSlideList}>
            {cardList.map((post, i) => (
              <div
                key={post.illust_no}
                onClick={() => {
                  navigate(
                    `/visible/card/detail/?illust_no=${post.illust_no}&currentPage=${slidePage}&pagePostSize=${pagePostSize}`
                  )
                }}
                style={{
                  fontWeight:
                    Number(illust_no) === post.illust_no ? "700" : "400",
                }}
              >
                <div>
                  <div>
                    {customCount(
                      getEpisode(cardTotal, slidePage, pagePostSize, i)
                    )}
                    화
                  </div>
                  <div style={{ cursor: "pointer" }}>{post.subtitle}</div>
                </div>
                <div>{post.hit} 읽음</div>
              </div>
            ))}
          </div>
        </SlidePagination>
      </div>
    </Layout>
  )
}

export default Detail
