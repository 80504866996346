import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
const NewTag = props => {
  const [newTag, setNewTag] = useState(false)
  console.log(newTag)
  useEffect(() => {
    const resiterDate =
      typeof props.registerTime == "string"
        ? dayjs(props.registerTime).unix()
        : props.registerTime

    setNewTag(resiterDate >= dayjs().subtract(7, "days").unix())
  }, [props])
  return (
    <>
      {newTag ? (
        <div
          style={{
            backgroundColor: "#525252",
            height: "22px",
            padding: "0 5px",
            lineHeight: "20px",
          }}
        >
          NEW
        </div>
      ) : null}
    </>
  )
}

export default NewTag
